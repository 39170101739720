import React, { useEffect } from 'react';
import './chatbot.css'

function TawkToWidget() {
  useEffect(() => {
    var Tawk_API = Tawk_API || {};
    var Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/658e8d170ff6374032ba43ff/1hiqde94k';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
  }, []);

  return (
    <div>
      {/* Your other JSX content */}
    </div>
  );
}

export default TawkToWidget;
