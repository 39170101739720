import React from 'react';
import { useNavigate } from 'react-router-dom';
import TawkToWidget from '../../common/chatbot/chatbot';
import Header from '../../common/head/head';
import Nav from '../../common/nav/nav';
import Footer from '../../common/footer/footer';
import './career.css';

function Careers() {
  return (
    <div className="home-background">
      <Header />
      <Nav />
      <div className="careers">
          <h1>Careers at Stark Design Labs</h1>
          <p>Join our innovative team and shape the future!</p>
  

        <section className="apply-info">
          <h2>How to Apply</h2>
          <p>
            If you are a DIY creator.
            If you are a DR. (ANY Type of Dr.)
            If you are a Scientist.
            If you are a Physicist.
            If you are an Engineer (Structural-Electrical-Mechanical-Bio or Any Other)
            If you are an Electrician.
            If you are a Plumber.
            If you are a Welder.
            If you are a Computer Aided Drafter.
            If you are a Software engineer.
            If you are a Computer hardware engineer.
            If you are a Doer and have a skill that could help.
            Please reach out, and we will try to bring you on. We need people to help grow this endeavor by becoming builders, assemblers, designers, fabricators, and by doing research on how submitted inventions work, learning how they can be perfected and made to function effectively and efficiently.
          </p>
          <p>
            Imagine a large facility filled with excellent lighting, thousands of parts bins that have every imaginable trinket, 3D printers, assembly and testing tables everywhere, lathes, extruders, circuit board creation stations, welders, wind tunnel testing stations, fluid dynamic research laboratories, Ohm's Law studies, thermodynamics testing, and people everywhere working to create 100 different projects all at once.
          </p>
          <p>
            Apply to be a Lab Rat. It’s a great home for anyone who has a belief in being a net benefit for as many people as possible all while enjoying the fun of creating cool new gadgets and watching knowledge work for good. We are small now but that is temporary with your help.
          </p>
          <p>
            Fill out the following form with your personal information, and we will get back to you quickly. No discrimination, come as you are, come ready to work.
          </p>
          <p>
            Thank you, STARK Design Labs, Adam Peer, and the other Lab Rats.
          </p>
        </section>
        <section className="email-contact">
          <h2>More Information</h2>
          <p>
            For more information or if you have specific questions, please reach out to us at:
            <a href="mailto:contact@starkdesignlabs.com" className="email-link">
              contact@starkdesignlabs.com
            </a>
          </p>
        </section>
        <TawkToWidget />
      </div>
      <Footer />
    </div>
  );
}

export default Careers;
