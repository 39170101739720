import React from 'react';
import { useNavigate } from 'react-router-dom';
import Nav from '../../common/nav/nav';
import TawkToWidget from '../../common/chatbot/chatbot';
import Footer from '../../common/footer/footer';
import Header from '../../common/head/head';

import image1 from './1.png'; // Import the image
import image2 from './2.png'; // Import another image
import image3 from './3.png'; // Import another image
import image4 from './4.jpg';

import './home.css';

function Home() {
  const navigate = useNavigate();

  const sections = [
  {
    title: 'Corporate Design and Ideology',
    img: image1,
    alt: 'Apply',
    text: "We are here to bring the unknown creators from around the world with their products and solutions to the forefront of new technology and problem-solving innovation and creation. There are inventors and problems solvers whose creativity and problem-solving skills would have never seen the light of day but we at Stark Design Labs know that we the people need these brilliant individuals, and we are fixing this problem NOW.",
  },
  {
    title: 'Mission Statement',
    img: image2,
    alt: 'Prototype',
    text: "Our mission is to open a line of opportunity for the unknown problem-solving individuals throughout the world. We will manufacture functioning prototypes that will fix real world problems. We will work with scientists, engineers and technicians of every discipline to bring about a renaissance of knowledge. Our Lab Rats work with the sole purpose of making innovations from around the world into physical products and solutions that change the reality of life as we know it.",
  },
  {
    title: 'Creative Goals',
    img: image3,
    alt: 'License',
    text: "We are going to initiate changes like creating new types of water transportation systems so that anyone anywhere can have clean usable water. We are going to enable electric cars to become truly viable and functional without destroying the power grids and poisoning the planet. We will make homelessness extinct. We will be a life-changing institution for everyone who lives and breathes.",
  },
  {
    title: 'Mission Possible',
    img: image4,
    alt: 'Movement',
    text: "This has never been attempted before but that is why we are doing it. We are with YOUR HELP going to change the life of every being on planet Earth. We have started this endeavor with our own funding, now gifts and donations from every source available are being welcomed and received to advance our prototyping process and onboarding of new lab rats from across the globe. Without a doubt we need the people of the world to donate regularly to help get the job done. Big tech won’t be helping, or they already would have….  Join the march for the people’s innovation and donate financial solutions and creative solutions right now.",
  }
];


  const applyNow = () => {
    navigate('/apply');
  };

  return (
    <div className="home-background">
      <Header />
      <Nav />
<main>
  {sections.map((section, index) => (
    <div key={index} className="section">
      <h2 className="section-title">{section.title}</h2>
      <img src={section.img} alt={section.alt} />
      <p>{section.text}</p>
      {section.buttonText && (
        <button onClick={applyNow}>{section.buttonText}</button>
      )}
    </div>
  ))}
</main>

      <TawkToWidget />
      <Footer />
    </div>
  );
}

export default Home;
