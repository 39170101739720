import React from 'react';
import { Link } from 'react-router-dom';
import './nav.css';

function Nav() {
  return (
    <nav>
      <Link to="/">Home</Link>
      <Link to="/contact">Contact</Link>
      <Link to="/apply">Apply</Link>
      <Link to="/donate">Donate</Link>
      <Link to="/career">Careers</Link>
    </nav>
  );
}

export default Nav;

