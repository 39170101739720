import React from 'react';
import Nav from '../../common/nav/nav';
import Footer from '../../common/footer/footer';
import TawkToWidget from '../../common/chatbot/chatbot';
import Header from '../../common/head/head';
import ContactForm from './ContactForm';
import './contact.css';

function Contact() {
  return (
    <div className="home-background">
      <Header />
      <Nav />
      <main>
        <section id="contact-info">
          <h2>Contact Information</h2>
          <p>Find our contact details below to get in touch with us directly.</p>
          <ul>
            <li><strong>Email:</strong> contact@starkdesignlabs.com</li>
            <li><strong>Phone:</strong> 903-294-9210</li>
            <li><strong>Address:</strong> P.O. Box 2561 Sherman, TX 75091</li>
          </ul>
        </section>

        <section id="contact-form">
          <h2>Send Us a Message</h2>
          <ContactForm /> {/* Using the ContactForm component */}
        </section>
      </main>
      <TawkToWidget />
      <Footer />
    </div>
  );
}

export default Contact;
