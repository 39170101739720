import React from 'react';
import Nav from '../../common/nav/nav';
import Footer from '../../common/footer/footer';
import Header from '../../common/head/head';
import TawkToWidget from '../../common/chatbot/chatbot';
 import DonationForm from './DonateForm';
import './donate.css';
{/* import CommentSection from './comment'; */}


function Donate() {
  return (
    <div className="home-background">
      <Header />
      <Nav />
        <main> 
          <section id="donation-section">
            <h2>Support Our Work</h2>
            <p>Your donations help us continue our mission. Any amount is greatly appreciated.</p>
           <DonationForm /> 
            {/* <CommentSection /> */}
            
            
          </section>
            <section id="welcome-section">
            <h2>Welcome to the Donation Page</h2>
            <p>Thanks for coming to STARK Design Labs and learning about what we do and why we do it.</p>
            <p>To create this dream and fulfill our goals of being a life-changing institution for everyone who lives and breathes we need a big ass family of givers, promoters, and creative solution submitters.</p>
            <p>We could really use a donation from you and basically everyone you know. Please consider a monthly subscription donation of any amount. Remember STARK Design Labs does not charge for the prototypes we produce or the patents we file, so donations are wildly important.</p>
            <p>ALSO – Please help us some more by hyping us up, help build our reputation and promote us! Make sure everyone knows the name STARK Design Labs! Use our name, post memes about us, #hashtag till the stripes wear out.</p>
            <p>Building from the ground up requires us to have a lot of support and you are that support.</p>
            <p>Thank you so very much!</p>
            <p>Join us, become a Lab Rat and work for a future we will be proud of!!!</p>
            <p>Sincerely, STARK Design Labs – Adam Peer and ALL the other Lab Rats</p>
            <p>Have you submitted your creation yet?</p>
          </section>
        </main>
      <TawkToWidget />
      <Footer />
    </div>
  ); 
}

export default Donate;