// ContactForm.js
import React, { useState } from 'react';

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  
  const handleSubmit = async (e) => {
  e.preventDefault();


  const encodedData = new URLSearchParams(formData).toString();

  try {
    const response = await fetch('https://vqyloj5nek.execute-api.us-east-1.amazonaws.com/production/contact-function', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: encodedData, // Send the encoded data
    });

    if (response.ok) {
      alert('Message sent!');
      setFormData({ name: '', email: '', message: '' }); // Reset form
    } else {
      alert('Failed to send message!');
    }
  } catch (error) {
    console.error('Error:', error);
    alert('An error occurred. Please try again later.');
  }
};


  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="name">Name:</label>
      <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />

      <label htmlFor="email">Email:</label>
      <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />

      <label htmlFor="message">Message:</label>
      <textarea id="message" name="message" value={formData.message} onChange={handleChange} required></textarea>

      <input type="submit" value="Send" />
    </form>
  );
}

export default ContactForm;
