import React from 'react';
import './footer.css';

function Footer() {
  return (
    <footer>
        <p>&copy; 2023 Stark Design Labs</p>
        <a href="tos.html" target="_blank" rel="noopener noreferrer">Terms of Service</a>
        <a href="privacy.html" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
        <div id="social-media-links">
            <a href="https://www.youtube.com/@STARKDesignLabs" target="_blank" rel="noopener noreferrer">Youtube</a>
            <a href="https://www.linkedin.com/company/stark-design-labs/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
            <a href="https://wwww.facebook.com/profile.php?id=61554917196090&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">Facebook</a>
            <a href="https://www.instagram.com/stark_design_labs?igsh=MWY0Y3FkM204ZRxZw==" target="_blank" rel="noopener noreferrer">Instagram</a>
            <a href="https://tiktok.com/@StarkDesignLabs" target="_blank" rel="noopener noreferrer">TikTok</a>
            <a href="https://x.com/STARKDesignLabs?t=dvsbd1-9voXtIJX63nkaCg&s=09" target="_blank" rel="noopener noreferrer">X</a>
            <a href="https://apps.apple.com/us/app/bible/id282935706" target="_blank" rel="noopener noreferrer">Free Bible</a>
        </div>
    </footer>
  );
}

export default Footer;
