import React, { useState } from 'react';
import FileUpload from './FileUpload';
import './ApplyForm.css';

function ApplyForm() {
  const [formData, setFormData] = useState({
    fullname: '',
    business: '',
    email: '',
    phone: '',
    inventionName: '',
    description: '',
    additionalInfo: '',
    fileKey: '', 
  });

  const [uploadSuccess, setUploadSuccess] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log('Updating form data:', { [name]: value });
    setFormData({ ...formData, [name]: value });
  };

  const handleFileUploadSuccess = (fileKey) => {
    setFormData({ ...formData, fileKey });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Form submission started with data:', formData);

    try {
      const formBody = new URLSearchParams(formData).toString();

      console.log('Submitting the form');
      const formResponse = await fetch('https://3zieo0gnn6.execute-api.us-east-1.amazonaws.com/Production/form-function', {
        method: 'POST',
        body: formBody,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      if (formResponse.ok) {
        console.log('Form submission successful');
        setUploadSuccess(true); // Set the success state to true
      }
    } catch (error) {
      console.error('Error in submitting form:', error);
      setUploadSuccess(false);
    }
  };

  return (
    <main>
      <h1>Submit Your Creation</h1>
      <p>Please fill out this form to submit your creation to Stark Design Labs.</p>
      <form onSubmit={handleSubmit}>
        <div className="form-section">
          <label htmlFor="fullname">Full Name:</label>
          <input type="text" id="fullname" name="fullname" value={formData.fullname} onChange={handleInputChange} required />

          <label htmlFor="business">Business:</label>
          <input type="text" id="business" name="business" value={formData.business} onChange={handleInputChange} required />

          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleInputChange} required />

          <label htmlFor="phone">Phone Number:</label>
          <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleInputChange} />

          <label htmlFor="inventionName">Creation Name:</label>
          <input type="text" id="inventionName" name="inventionName" value={formData.inventionName} onChange={handleInputChange} required />

          <label htmlFor="description">Description:</label>
          <textarea id="description" name="description" value={formData.description} onChange={handleInputChange} required />

          <label htmlFor="additionalInfo">Additional Information:</label>
          <textarea id="additionalInfo" name="additionalInfo" value={formData.additionalInfo} onChange={handleInputChange} />
        </div>

<a href="https://sdl-server.s3.amazonaws.com/agreement.pdf" download="Agreement.pdf" className="download-link" target="_blank">
    Download and Sign the Agreement.
</a>



        <FileUpload onFileUploadSuccess={handleFileUploadSuccess} />

        <div className="form-section">
          <input type="checkbox" id="agree" name="agree" required />
          <label htmlFor="agree">I agree to the Terms of Service and acknowledge the equity offering.</label>
        </div>

        <button type="submit">Submit</button>
        {uploadSuccess && <div className="upload-success">Form submitted successfully!</div>}
      </form>

      <p>Note: Not all submissions will be pursued. Selected individuals will be contacted for further discussion.</p>
    </main>
  );
}


export default ApplyForm;