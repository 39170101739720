import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/pages/home/home';
import Contact from './components/pages/contact/contact';
import Apply from './components/pages/apply/apply';
import Donate from './components/pages/donate/donate';
import Careers from './components/pages/career/career';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/contact" element={<Contact />} />
          <Route path="/apply" element={<Apply />} />
          <Route path="/donate" element={<Donate />} />
          <Route path="/career" element={<Careers />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
