import React from 'react';
import { Link } from 'react-router-dom';
import './head.css';
import logo from './sdl-logo.jpg'; // Import the logo image

function Header() {
  return (
    <header>
      <div className="quote">
        <p>"The role of a leader is not to come up with all the great ideas. The role of a leader is to create an environment in which great ideas can happen."</p>
        <p className="author">- Simon Sinek</p>
      </div>
      <Link to="/">
        <img src={logo} alt="SDL Logo" /> {/* SDL Logo */}
      </Link>
      {/* Add other header contents here if needed */}
    </header>
  );
}

export default Header;
