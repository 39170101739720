import React, { useState } from 'react';
import './DonateForm.css';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';

// Load the Stripe script with your publishable key
const stripePromise = loadStripe('pk_live_51OGmaHEGhFIGa0otEMNKnBg8iTAMnMUtbFS0nCWEgy6qkS8Zlcw6XcQeS56dLACGCIyN23B8wuQYprAFKZRdLXGd00bBN1W3GG');


const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [billingDetails, setBillingDetails] = useState({ name: '', email: '' });
    const [donationAmount, setDonationAmount] = useState('');
    const [isDonated, setIsDonated] = useState(false); // New state for donation status

    const handleInputChange = (e) => {
        setBillingDetails({ ...billingDetails, [e.target.name]: e.target.value });
    };

    const handleDonationAmountChange = (amount) => {
        setDonationAmount(amount);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            console.error('Stripe has not loaded yet.');
            return;
        }

        const cardElement = elements.getElement(CardElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: billingDetails,
        });

        if (error) {
            console.error('[error]', error);
            return;
        }

        const urlEncodedData = `payment_method_id=${encodeURIComponent(paymentMethod.id)}&name=${encodeURIComponent(billingDetails.name)}&email=${encodeURIComponent(billingDetails.email)}&donation_amount=${encodeURIComponent(donationAmount)}`;

        try {
            const response = await fetch('https://8v6tx5onqh.execute-api.us-east-1.amazonaws.com/production/donate-function', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: urlEncodedData,
            });

            const data = await response.json();
            if (response.ok) {
                setIsDonated(true); // Update donation status on success
            }
            console.log('Response data:', data);
        } catch (error) {
            console.error('Payment API error:', error);
        }
    };



    return (
        <form onSubmit={handleSubmit} className="stripe-form">
            <div className="donation-amounts">
                {[25, 50, 75, 100].map((amount) => (
                    <button
                        key={amount}
                        type="button"
                        onClick={() => handleDonationAmountChange(amount)}
                        className={`donation-amount-button${donationAmount === amount ? ' selected' : ''}`}
                    >
                        ${amount}
                    </button>
                ))}
                <div>
                    <label htmlFor="custom-amount">Custom amount:</label>
                    <input
                        id="custom-amount"
                        type="number"
                        placeholder="Enter your amount"
                        value={donationAmount}
                        onChange={(e) => handleDonationAmountChange(e.target.value)}
                        className="custom-amount-input"
                    />
                </div>
            </div>
            <input type="text" name="name" placeholder="Full name" required onChange={handleInputChange} />
            <input type="email" name="email" placeholder="Email" required onChange={handleInputChange} />
            <CardElement />
             <button type="submit" disabled={!stripe || isDonated} className="stripe-pay-button">
                {isDonated ? 'Success' : `Donate $${donationAmount || '...'}`}
            </button>
        </form>
    );
};

const DonationForm = () => {
    const [isCopied, setIsCopied] = useState(false);
    const [selectedCrypto, setSelectedCrypto] = useState('Bitcoin');
    const cryptoAddresses = {
        Bitcoin: 'bc1qmgfknxav9njxwje4r36q5zp8dynjaya7r75347',
        Ethereum: '0x7dD50b28FAF2d3A31A3736F169BF8FEe22A30AF3',
        Polygon: '0x7dD50b28FAF2d3A31A3736F169BF8FEe22A30AF3',
        ArbitrumOne: '0x7dD50b28FAF2d3A31A3736F169BF8FEe22A30AF3'
    };

    const copyToClipboard = (cryptoAddress) => {
        if (typeof window !== "undefined" && window.navigator && window.navigator.clipboard) {
            window.navigator.clipboard.writeText(cryptoAddress).then(() => {
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 2000);
            }).catch(err => {
                console.error('Failed to copy text: ', err);
            });
        } else {
            console.error('Clipboard not available on this platform');
        }
    };

    return (
        <div className="donation-container">
            <div className="credit-card-form">
                <h3>Donate with a Credit or Debit Card!</h3>
                <Elements stripe={stripePromise}>
                    <CheckoutForm />
                </Elements>
            </div>

            <div className="crypto-form">
                <h3>Donate with Cryptocurrency!</h3>
                <div>
                    <label htmlFor="crypto-select">Choose a currency:</label>
                    <select
                        id="crypto-select"
                        value={selectedCrypto}
                        onChange={(e) => setSelectedCrypto(e.target.value)}
                        className="crypto-dropdown"
                    >
                        {Object.keys(cryptoAddresses).map((currency) => (
                            <option key={currency} value={currency}>
                                {currency}
                            </option>
                        ))}
                    </select>
                </div>
                <p>{cryptoAddresses[selectedCrypto]}</p>
                <button onClick={() => copyToClipboard(cryptoAddresses[selectedCrypto])} className="copy-address-button">
                    {isCopied ? 'Copied!' : 'Copy Address'}
                </button>
                <p>
                    Don't have a wallet?
                </p>
                <p>
                    <a href="https://giddy.co" target="_blank" rel="noopener noreferrer">Get one today!</a>
                </p>
            </div>
        </div>
    );
};

export default DonationForm;
