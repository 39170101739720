import React, { useState } from 'react';
import axios from 'axios';

const FileUpload = () => {
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [generalFiles, setGeneralFiles] = useState([]);
  const [pdfFiles, setPdfFiles] = useState([]);

  const handleGeneralFileChange = async (event) => {
    const newFiles = Array.from(event.target.files);
    setGeneralFiles(prevFiles => [...prevFiles, ...newFiles]); // Append new files to the existing list
    await uploadFiles(newFiles);
  };

  const handlePDFFileChange = async (event) => {
    const newFiles = Array.from(event.target.files);
    setPdfFiles(prevFiles => [...prevFiles, ...newFiles]); // Append new files to the existing list

    const pdfIncluded = newFiles.some(file => file.type === 'application/pdf');
    if (!pdfIncluded) {
      console.error('PDF file missing in the selection');
      alert('Please include a PDF file.');
      return;
    }

    await uploadFiles(newFiles);
  };

  const uploadFiles = async (files) => {
    try {
      console.log('Requesting pre-signed URLs for files');
      const preSignedUrls = await requestPreSignedUrls(files);
      console.log('Received pre-signed URLs');

      await Promise.all(files.map((file, index) => {
        const url = preSignedUrls[index];
        console.log(`Uploading file ${file.name} to S3`);
        return uploadFileToS3(url, file);
      }));

      console.log('All files uploaded successfully');
      setUploadSuccess(true); // Set the success state to true
    } catch (error) {
      console.error('Error in uploading files:', error);
      setUploadSuccess(false);
    }
  };

  const requestPreSignedUrls = async (files) => {
    try {
      const response = await axios.post('https://h218tl3dnh.execute-api.us-east-1.amazonaws.com/url', {
        files: files.map(file => ({ name: file.name, type: file.type }))
      });
      console.log('Pre-signed URL request successful');
      return response.data.urls;
    } catch (error) {
      console.error('Error requesting pre-signed URLs:', error);
      throw error; // Re-throw the error to be caught by the caller
    }
  };

  const uploadFileToS3 = async (url, file) => {
    try {
      await axios.put(url, file, {
        headers: {
          'Content-Type': file.type
        }
      });
      console.log(`File ${file.name} uploaded successfully`);
    } catch (error) {
      console.error(`Error uploading file ${file.name} to S3:`, error);
      throw error; // Re-throw the error to be caught by the caller
    }
  };

  return (
    <div className="upload-section">
      <label htmlFor="generalFileUpload">Upload any invention submission documentation files here::</label><br />
      <input
        type="file"
        id="generalFileUpload"
        name="generalFileUpload"
        onChange={handleGeneralFileChange}
        multiple
        accept="image/*, video/*, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
      <div>
        {generalFiles.map((file, index) => <div key={index}>{file.name}</div>)}
      </div><br />

      <label htmlFor="pdfFileUpload">Upload only PDF signed agreement files here:</label><br />
      <input
        type="file"
        id="pdfFileUpload"
        name="pdfFileUpload"
        onChange={handlePDFFileChange}
        multiple
        accept="application/pdf"
      />
      <div>
        {pdfFiles.map((file, index) => <div key={index}>{file.name}</div>)}
      </div>

      {uploadSuccess && <div className="upload-success">Files uploaded successfully!</div>}
    </div>
  );
};

export default FileUpload;
