import React from 'react';
import Nav from '../../common/nav/nav';
import Footer from '../../common/footer/footer';
import Header from '../../common/head/head';
import TawkToWidget from '../../common/chatbot/chatbot';
import ApplyForm from './ApplyForm';
import './apply.css';

function Apply() {
  return (
    <div className="home-background">
    <Header />
      <Nav />
      <ApplyForm />
      <TawkToWidget />
      <Footer />
    </div>
  );
}

export default Apply;
